.summary-container {
    display: flex;
    min-width: 768px;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.20);
}

.total-hours {
    align-self: stretch;
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.quote {
    align-self: stretch;
    color: rgba(19, 20, 22, 0.56);
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;
}

.progress-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.steps-container {
    display: flex;
    width: 100%;
    margin-top: 24px;
    justify-content: space-between;
}

.progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.progress-step-label {
    color: rgba(19, 20, 22, 0.56);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.progress-bar-container {
    width: 100%;
    margin-top: 8px;
    position: relative;
}

.target {
    width: 100%;
    height: 16px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
}

.achieved {
    height: 16px;
    border-radius: 8px;
    position: absolute;
    top: 1px;
}

.stats-container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.20);
}
