.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.login-details {
    display: flex;
    width: 800px;
    height: 600px;
    background: white;
    border-radius: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.login-left-side,
.login-right-side {
    display: flex;
    width: 50%;
    height: 100%;
}

.login-left-side img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
}

.login-right-side-container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 40px);
    height: auto;
    justify-content: center;
    margin: 20px;
}

.login-right-side-container h1 {
    color: orangered;
}

.login-right-side-container img {
    display: flex;
    width: 160px;
    align-self: center;
    margin-top: 10px;
}

.login-details h1, h4 {
    text-align: center;
    margin: 10px 0px;
}

.login-details .login-button {
    align-self: center;
    margin-top: 40px;
}

