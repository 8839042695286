.header-container {
    background: #044EDF;
    display: inline-flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-container img {
    height: 24px;
    margin-left: 24px;
}

.profile-container {
    display: flex;
    align-items: center;
    margin: 8px 0;
}

.profile-container img {
    margin: 0px;
}

.profile-container .profile-image {
    height: 48px;
    margin-right: 24px;
    cursor: pointer;
    border-radius: 50px;
}

.btn-logout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    cursor: pointer;
    color: white;
}

.profile-dropdown-menu {
    position: absolute;
    top: 56px;
    right: 4px;
    background: white;
    padding: 8px;
    font-size: large;
    font-weight: 600;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    cursor: pointer;
    z-index: 2;
}

